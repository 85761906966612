import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
  writeValue(): void {},
  registerOnChange(): void {},
  registerOnTouched(): void {},
};

export abstract class WrappedFormControlComponent {
  constructor(private _ngControl: NgControl) {
    if (this._ngControl) {
      this._ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }
  }

  // Necessary to satisfy type checking
  get control() {
    return this._ngControl.control as FormControl;
  }
}
